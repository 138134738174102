import trim from 'lodash/trim'

export const getCityUrlPart = (city?: string | null) => {
  if (!city) return ''
  let url = city
  url = url.toLowerCase()
  url = url.replace(/\s/g, '-')
  url = url.replace(/ä/g, 'ae')
  url = url.replace(/ö/g, 'oe')
  url = url.replace(/ü/g, 'ue')
  url = url.replace(/ß/g, 'ss')
  url = url.replace(/[^a-z-]/g, '')
  url = trim(url, '-')
  return url
}

// FROM: https://stackoverflow.com/questions/26054846/generate-slug-with-german-umlauts
// $text = 'Ein schöner Text';
// $text = preg_replace('~[^\p{L}\d]+~u', '-', $text);
// $text = trim($text, '-');
// $text = iconv('utf-8', 'ASCII//TRANSLIT', $text);
// $text = strtolower($text);
// $text = preg_replace('~[^-\w]+~', '', $text);
// echo $text;
// output: ein-schoner-text
