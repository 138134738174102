import { ChevronDown } from '@dreamstack/icons'
import type { FormControlProps, SelectProps } from '@material-ui/core'
import { FormControl, InputLabel, Select, withStyles } from '@material-ui/core'
import merge from 'lodash/merge'
import type { FunctionComponent } from 'react'
import { useFormContext } from 'react-hook-form'
import TailwindConfig from '../../../../tailwind.config'
import { LayoutProps } from './MuiInputLayoutProps'
import { SimpleFieldError } from './SimpleFieldError'

const defaultSelectProps: SelectProps = {
  disableUnderline: true,
}

const defaultFormControlProps: FormControlProps = {
  variant: 'filled',
  fullWidth: true,
}

export const SimpleSelect: FunctionComponent<
  React.PropsWithChildren<{
    name?: string
    selectProps?: SelectProps
    formControlProps?: FormControlProps
    label?: string
    errorMessage?: string
    isOnDarkBg?: boolean
  }>
> = ({
  children,
  selectProps,
  formControlProps,
  label,
  errorMessage,
  name,
  isOnDarkBg = false,
}) => {
  const mergedSelectProps = merge(defaultSelectProps, selectProps)
  const mergedFormControlProps = merge(
    defaultFormControlProps,
    formControlProps
  )
  const formContext = useFormContext()
  const error = name
    ? formContext.getFieldState(name, formContext.formState).error?.message ??
      errorMessage
    : errorMessage

  return isOnDarkBg ? (
    <div>
      <FormControlStyledOnDark {...mergedFormControlProps}>
        {!!label && (
          <InputLabelStyledOnDark id="demo-simple-select-outlined-label">
            {label}
          </InputLabelStyledOnDark>
        )}
        <SelectStyled
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          IconComponent={ChevronDown}
          {...mergedSelectProps}
          label={label}
          placeholder={label}
          error={!!error}
        >
          {children}
        </SelectStyled>
      </FormControlStyledOnDark>
      {!!error && <SimpleFieldError>{error}</SimpleFieldError>}
    </div>
  ) : (
    <div>
      <FormControlStyledOnLight {...mergedFormControlProps}>
        {!!label && (
          <InputLabelStyledOnLight id="demo-simple-select-outlined-label">
            {label}
          </InputLabelStyledOnLight>
        )}
        <SelectStyled
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          IconComponent={ChevronDown}
          {...mergedSelectProps}
          label={label}
          placeholder={label}
          error={!!error}
        >
          {children}
        </SelectStyled>
      </FormControlStyledOnLight>
      {!!error && <SimpleFieldError>{error}</SimpleFieldError>}
    </div>
  )
}

const InputLabelStyledOnDark = withStyles((theme) => ({
  root: {
    fontSize: 16,
    zIndex: 1,
    '& .MuiSelect-root': {
      backgroundColor: '#4b4e5c',
      '&.Mui-error': {
        borderColor: TailwindConfig.theme.extend.colors.accentroError['full'],
      },
    },
    '& .MuiSelect-input': {
      color: TailwindConfig.theme.extend.colors.accentroWhite['full'],
    },
    '& .MuiSelect-filled': {
      color: TailwindConfig.theme.extend.colors.accentroWhite['full'],
    },
    '& .MuiSelect-shrink': {
      color: TailwindConfig.theme.extend.colors.accentroWhite['500'],
    },
  },
  shrink: {
    transform: 'translate(8px, 8px) scale(0.75)',
  },
}))(InputLabel)

const InputLabelStyledOnLight = withStyles((theme) => ({
  root: {
    fontSize: 16,
    zIndex: 1,

    '& .MuiSelect-root': {
      backgroundColor: 'transparent',
      '&.Mui-error': {
        borderColor: TailwindConfig.theme.extend.colors.accentroError['full'],
      },
      '& .MuiSelect-input': {
        color: TailwindConfig.theme.extend.colors.accentroGray['full'],
      },
      '& .MuiSelect-filled': {
        color: TailwindConfig.theme.extend.colors.accentroGray['500'],
      },
    },
  },
  shrink: {
    transform: 'translate(8px, 8px) scale(0.75)',
  },
}))(InputLabel)

const FormControlStyledOnDark = withStyles((theme) => ({
  root: {
    ...merge(LayoutProps(theme), {
      minWidth: 120,
      backgroundColor: '#4b4e5c',
      '& .MuiFormLabel-root': {
        color: TailwindConfig.theme.extend.colors.accentroWhite['full'],
      },
      '& .MuiInputLabel-filled': {
        color: TailwindConfig.theme.extend.colors.accentroWhite['full'],
      },
      '& .MuiInputLabel-shrink': {
        color: TailwindConfig.theme.extend.colors.accentroWhite['500'],
      },
      '& .MuiSelect-root.Mui-focused': {
        background: 'transparent',
      },
      '& .MuiSelect-root.MuiSelect-filled': {
        color: TailwindConfig.theme.extend.colors.accentroWhite['full'],
      },
      '& .MuiSelect-root:hover': {
        background: 'transparent',
      },
      '& .MuiSelect-root': {
        background: 'transparent',
      },
      '& .MuiSelect-icon': {
        color: TailwindConfig.theme.extend.colors.accentroWhite['full'],
      },
    }),
  },
}))(FormControl)

const FormControlStyledOnLight = withStyles((theme) => ({
  root: {
    ...merge(LayoutProps(theme), {
      minWidth: 120,
      backgroundColor: TailwindConfig.theme.extend.colors.accentroGray['50'],
      '& .MuiFilledInput-root': {
        backgroundColor: 'transparent',
      },
      '& .MuiSelect-root.Mui-focused': {
        background: 'transparent',
      },
      '& .MuiInputLabel-filled': {
        color: TailwindConfig.theme.extend.colors.accentroGray['500'],
      },
      '& .MuiInputLabel-shrink': {
        color: TailwindConfig.theme.extend.colors.accentroGray['500'],
      },
      '& .MuiSelect-root:hover': {
        background: 'transparent',
      },
      '& .MuiSelect-root': {
        background: 'transparent',
      },
      '& .MuiSelect-icon': {
        color: TailwindConfig.theme.extend.colors.accentroGray['full'],
      },
    }),
  },
}))(FormControl)

const SelectStyled = withStyles((theme) => ({
  filled: {
    background: TailwindConfig.theme.extend.colors.accentroGray['50'],
    color: TailwindConfig.theme.extend.colors.accentroGray['full'],
    '&:focus': {},

    padding: '27px 16px 10px !important',
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      fontSize: '24px',
      padding: '27px 32px 10px !important',
    },
  },
  root: {
    padding: (props: any) =>
      !!props.label ? '27px 12px 10px' : '12px 14px 10px',
  },
  outlined: {
    color: 'black',
    padding: theme.spacing(4),
  },
  icon: {
    width: '32px',
    height: '32px',
    padding: 0,
  },
  iconOutlined: {
    color: TailwindConfig.theme.extend.colors.accentroGray['500'],
  },
}))(Select)
