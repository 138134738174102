import { Close } from '@dreamstack/icons'
import { Popover } from '@headlessui/react'
import { default as React} from 'react';
import type { FunctionComponent, MouseEvent } from 'react'
import 'twin.macro'
import ReactPortal from './ReactPortal'

export const SimpleNotificationPopover: FunctionComponent<React.PropsWithChildren<{
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setPopperElement: React.Dispatch<(prevState: undefined) => undefined>
  styles: any
  attributes: any
  open: boolean
  showButtons?: boolean
}>> = ({ setOpen, setPopperElement, styles, attributes, open, children }) => {
  return (
    <Popover>
      {open && (
        <ReactPortal wrapperId="react-portal-modal-container">
          <Popover.Panel
            ref={setPopperElement}
            onClick={(event: MouseEvent<Element>) => event.preventDefault()}
            tw="cursor-default z-10"
            style={styles.popper}
            {...attributes.popper}
            static
          >
            <div tw="px-px-8 sm:px-0">
              <div tw="sm:(min-width[340px] max-width[400px]) box-shadow[0 2px 19px 0 rgba(0, 0, 0, 0.2)]">
                <div tw="flex flex-wrap md:flex-nowrap bg-accentroWhite-full text-accentroGray-full p-px-24 relative">
                  <Close
                    onClick={(event) => {
                      event.stopPropagation()
                      event.preventDefault()
                      setOpen(false)
                    }}
                    tw="absolute cursor-pointer top-px-16 right-px-16 h-px-20 w-px-20 text-accentroGray-500 z-10"
                  />
                  {children}
                </div>
              </div>
            </div>
          </Popover.Panel>
        </ReactPortal>
      )}
    </Popover>
  )
}
