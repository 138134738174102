import type { IOtherLocaleUrlsContext, LocaleString } from '@dreamstack/i18n'
import { getCityUrlPart } from './getCityUrlPart'

export type GetPropertyUrlProps = {
  id: number
  city?: string | null
  locale: LocaleString
}

export type GetPropertyUrlPropsWithoutLocale = Omit<
  GetPropertyUrlProps,
  'locale'
>

export const propertiesOverviewOtherLocaleUrls: IOtherLocaleUrlsContext = {
  de: { url: '/immobilien' },
  en: { url: '/real-estate' },
}

export const UNKNOWN_CITY_URL_PART = {
  de: 'alle',
  en: 'all',
}

export const getCityUrl = ({
  city,
  locale,
}: Pick<GetPropertyUrlProps, 'city' | 'locale'>) => {
  const { url } = propertiesOverviewOtherLocaleUrls[locale]
  const cityUrlPart = getCityUrlPart(city) || UNKNOWN_CITY_URL_PART[locale]
  return `${url}/${cityUrlPart}`
}

export const getPropertyUrl = (options: GetPropertyUrlProps) => {
  const cityUrl = getCityUrl(options)
  return `${cityUrl}/${options.id}`
}

export const getPropertyOtherLocaleUrls = (
  options: GetPropertyUrlPropsWithoutLocale
) => {
  const otherLocaleUrls: IOtherLocaleUrlsContext = {
    de: { url: getPropertyUrl({ ...options, locale: 'de' }) },
    en: { url: getPropertyUrl({ ...options, locale: 'en' }) },
  }
  return otherLocaleUrls
}
