import { ArrowRight, CheckOutlined } from '@dreamstack/icons'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { default as React } from 'react'
import 'twin.macro'
import { SimpleMuiButton } from '../index'
import { SimpleNotificationPopover } from './SimpleNotificationPopover'

export type NotificationPopoverType = 'cta' | 'success'

export const NotificationPopover: FunctionComponent<React.PropsWithChildren<{
  type: NotificationPopoverType
  title: string
  text?: string
  buttonText: string
  buttonHref: string
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setPopperElement: React.Dispatch<(prevState: undefined) => undefined>
  styles: any
  attributes: any
}>> = ({
  type,
  title,
  text,
  buttonHref,
  buttonText,
  open,
  setOpen,
  setPopperElement,
  styles,
  attributes,
}) => {
  const isCta = type === 'cta'
  const isSucess = type === 'success'

  return (
    <SimpleNotificationPopover
      open={open}
      setPopperElement={setPopperElement}
      styles={styles}
      attributes={attributes}
      setOpen={setOpen}
    >
      {isSucess && (
        <div tw="mr-px-16 -ml-px-4 mb-px-8 md:(ml-0 mb-0)">
          <CheckOutlined tw="w-px-32 h-px-32 text-accentroLightGreen-full" />
        </div>
      )}
      <div>
        <div tw="text-px-16 leading-px-24 font-semibold">{title}</div>
        {text && <div tw="text-px-16 leading-px-24 mt-px-8">{text}</div>}
        <div tw="text-px-16 leading-px-24 mt-px-8">
          <Link href={buttonHref}>
            {isCta ? (
              <SimpleMuiButton
                variant={'outlined'}
                color={'secondary'}
                endIcon={isSucess && <ArrowRight tw="h-px-32 w-px-32" />}
              >
                {buttonText}
              </SimpleMuiButton>
            ) : (
              <SimpleMuiButton
                variant="text"
                color="primary"
                endIcon={<ArrowRight tw="h-px-32 w-px-32" />}
              >
                <span tw="text-left text-px-16 leading-px-24">
                  {buttonText}
                </span>
              </SimpleMuiButton>
            )}
          </Link>
        </div>
      </div>
    </SimpleNotificationPopover>
  )
}
