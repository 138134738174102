import { useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import type {
  NotificationPopoverType} from '../my/NotificationPopover';
import {
  NotificationPopover
} from '../my/NotificationPopover'
import type {
  NotificationPopupType} from '../notificationPopup/NotificationPopupProvider';
import {
  useNotificationPopupContext,
} from '../notificationPopup/NotificationPopupProvider'
import type { SimpleNotificationPopoverProps } from './useSimpleNotificationPopover'

type NotificationPopoverProps = {
  title: string
  text?: string
  buttonHref: string
  buttonText: string
  type: NotificationPopoverType
} & SimpleNotificationPopoverProps &
  (
    | {
        identifierType?: never
        isUnique?: never | false
        id?: never
      }
    | {
        id: string
        identifierType: NotificationPopupType
        isUnique: true
      }
  )

export const useNotificationPopover = ({
  title,
  text,
  buttonHref,
  buttonText,
  type,
  automaticCloseMs,
  options,
  id,
  identifierType,
  isUnique,
  offset,
}: NotificationPopoverProps) => {
  const { selectedNotificationPopup, setselectedNotificationPopup } =
    useNotificationPopupContext()
  const [open, setOpen] = useState<boolean>(false)

  const closeNotification = () => {
    setOpen(false)
  }
  const openNotification = () => {
    if (id) {
      setselectedNotificationPopup({ id, type: identifierType })
    }
    setOpen(true)
    if (automaticCloseMs) {
      setTimeout(() => setOpen(false), automaticCloseMs)
    }
  }

  useEffect(() => {
    if (
      isUnique &&
      selectedNotificationPopup?.id !== id &&
      selectedNotificationPopup?.type === identifierType
    )
      closeNotification()
  }, [id, identifierType, isUnique, selectedNotificationPopup])

  const [referenceElement, setReferenceElement] = useState<any>()
  const [popperElement, setPopperElement] = useState()
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: 'fixed',
    ...options,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: offset,
        },
      },
    ],
  })
  return {
    NotificationPopoverComponent: (
      <NotificationPopover
        setOpen={setOpen}
        open={open}
        type={type}
        title={title}
        text={text}
        buttonHref={buttonHref}
        buttonText={buttonText}
        setPopperElement={setPopperElement}
        styles={styles}
        attributes={attributes}
      />
    ),
    openNotification,
    closeNotification,
    setReferenceElement,
  }
}
